.custom-tooltip{
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 2px solid #3d6266;
  box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.step{
  color: #000000;
}
.progress{
  color: #000066;
}
.time-diff{
  color: #66CC99;
}
.help-asked{
  color: #ff7300;
}
.detail-asked{
  color: #9E7BB5;
}
.rollback{
  color: #FF0033;
}
.detail-level{
  color: #6D9EEB;
}
