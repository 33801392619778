.table {
  width: 100%;
  border-spacing: 0px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.table, th, td {
  border-top: 1px solid black;
  border-left: 1px solid black;
}
